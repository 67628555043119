import { _js } from '@ifixit/localize';

/**
 * A status panel that displays a message and (optionally) a loading icon in
 * the middle of the user's viewport. The panel is implemented as a static
 * class, so needn't do anything other than:
 *
 *    StatusPanel.loading('My own loading message'); // default timeout
 *    StatusPanel.loading('My own loading message', 20); // 20s timeout
 *    StatusPanel.deactivate(); // hide, cancel timeout
 *
 * If the timeout expires, the panel will hide and the page will reload.
 */
export const StatusPanel = {
   defaultTimeout: 10, // seconds
   minDisplay: 1, // seconds
   reload: true,
   timeouts: [],
   panel: null,
   message: null,
   image: null,
   showStart: null,

   /**
    * Presents the status panel with a custom message ("Loading data..." by
    * default) and a loading icon; takes an optional timeout, which overrides
    * the default.
    *
    * @param message string the message to display
    * @param timeout int the new timeout (in seconds, NOT thousandths)
    */
   loading(message, timeout = this.defaultTimeout, options = {}) {
      this.initialize();
      this.message.textContent = message || _js('Loading data...');
      this.image.style.display = 'block';
      setTimeout(() => {
         this.showPanel(timeout, options);
      }, 0);
   },

   /**
    * Presents the status panel with a custom message and NO loading icon;
    * otherwise behaves the same as loading().
    */
   notify(message, timeout = this.defaultTimeout, options = {}) {
      this.initialize();
      this.message.textContent = message;
      this.image.style.display = 'none';
      this.showPanel(timeout, options);
   },

   /**
    * Deactivates the status panel, hiding it and canceling the timeout.
    * @param noDelay removes delay from deactivating screen
    */
   deactivate(noDelay = false) {
      this.cancelTimeout();

      // hide the panel after minDisplay seconds, or immediately if that much
      // time has already passed
      let elapsed = (Date.now() - this.showStart) / 1000;
      let delay = noDelay ? 0 : Math.max(this.minDisplay - elapsed, 0);
      setTimeout(() => this.hidePanel(), delay * 1000);
   },

   /**
    * Inserts the panel into the page layout and sets appropriate styling.
    *
    * SHOULD NOT be called directly, as it will
    * be called from loading() or notify(); only initializes once.
    */
   initialize() {
      if (this.panel) {
         return;
      }

      this.message = document.createElement('p');
      this.message.textContent = _js('Loading data...');
      this.message.dataset.testid = 'status-loading';

      this.panel = document.createElement('div');
      this.panel.id = 'statusPanel';
      this.panel.addEventListener('click', () => this.hidePanel());

      this.image = document.createElement('div');
      this.image.className = 'throbber';

      this.panel.append(this.image);
      this.panel.append(this.message);
      document.body.append(this.panel);
   },

   /**
    * Centers and displays the panel with a timeout of `timeout` seconds.
    *
    * @param timeout the timeout in seconds
    */
   showPanel(timeout, options) {
      this.panel.classList.add('show-panel');
      this.setTimeout(timeout, options);
      this.showStart = Date.now();
   },

   hidePanel() {
      if (this.panel) {
         this.panel.classList.remove('show-panel');
         this.image.style.display = 'none'; // Ensure this is the correct place to hide the spinner
      }
   },

   /**
    * Sets a timeout on the panel, either using the passed in timeout, or if
    * no default is supplied, the default.
    *
    * @param timeout int the timeout in seconds
    */
   setTimeout(timeout, options) {
      timeout = (timeout ?? this.defaultTimeout) * 1000;
      this.timeouts.push(setTimeout(() => this.timeoutExpired(options), timeout));
   },

   /**
    * Cancels the latest timeout.
    */
   cancelTimeout() {
      if (this.timeouts.length) {
         clearTimeout(this.timeouts.pop());
      }
   },

   /**
    * Handles a timeout by hiding the loading image (if it's visible), and
    * reloads the page after two extra seconds of waiting.
    */
   timeoutExpired(options) {
      if (options && options.reload !== undefined && !options.reload) {
         this.deactivate(); // Consider how soon after this call the spinner should be hidden
         return;
      }

      setTimeout(() => {
         this.image.style.display = 'none';
         if (options.reload !== false) {
            location.reload();
         }
      }, 2000);
   },
};
