import { _js } from '@ifixit/localize';

export { doDropdownInit };
window.doDropdownInit = doDropdownInit;
function doDropdownInit(dropdownWrapperId = null) {
   // Fixing transitionend in older safari versions.
   let transitionend =
      'transition' in document.documentElement.style ? 'transitionend' : 'webkitTransitionEnd';

   // Detects IE 6-11. Source: https://stackoverflow.com/a/9851769
   // eslint-disable-next-line no-constant-binary-expression
   let isIE = /*@cc_on!@*/ false || !!document.documentMode;

   // Logic to close a dropdown when clicked outside of it.
   window.addEvent('click', closeByClickOut);

   if (dropdownWrapperId) {
      // Initialize singe dropdown element
      init($(dropdownWrapperId).addEvent('click', containBubble));
   } else {
      // Initialize all dropdown elements.
      $$('.dropdown-wrapper').addEvent('click', containBubble).each(init);
   }

   // Separate clicks between inside a dropdown element and outside.
   function containBubble(event) {
      event.stopPropagation();
   }

   // Close all dropdown elements.
   function closeByClickOut(event) {
      $$('.opened .selected').fireEvent('click');
      $$('.input-opened .cancel-input').fireEvent('click');
   }

   /*
   Change data-value attribute.
   Change selected element's textContent.
   Change selected element's hovered title value.
   Fire dropdown's valuechanged event.
   Change the selected option in the UI to the new value.
   If the new value isn't an option, create a new option at the bottom.

   @param {DOM element} dropdown:
      .dropdown-wrapper element.
   @param {Array | String | Number} value:
      new dropdown value to set.
      if value is an array then value is [key, value].
   @param {DOM element} domOption (OPTIONAL - speed up):
      .selection element in dropdown's .options div
      which has the value of the 'value' parameter.
   @param {Boolean} fireValueChangedEvent (OPTIONAL - default = true):
      Boolean to fire the 'valuechanged' event or not.
   @return {String}
      dropdown's old value.
   */
   function setValue(dropdown, value, domOption, fireValueChangedEvent) {
      let oldValue = dropdown.get('data-value'),
         key;

      if (Array.isArray(value)) {
         key = String(value[0]);
         value = String(value[1]);
      } else {
         value = String(value);
      }

      // If the new value is the same as the old value, exit.
      if (value === oldValue) {
         // Conditionally still fire the valuechanged event before exiting.
         if (fireValueChangedEvent !== false) {
            dropdown.fireEvent('valuechanged', dropdown);
         }
         return;
      }

      // If a DOM element isn't passed in, search for an option with the same
      // title as the value passed in.
      if (!domOption) {
         let options = dropdown.getElements('.options .selection:not(".more")');
         for (let i = 0; !domOption && i < options.length; i++) {
            if (options[i].title === value) {
               domOption = options[i];
               break;
            }
         }

         // If we didn't find any options by title, try looking by value.
         //
         // NOTE: This should probably be searched before title, but I'm not
         // sure the consequences of doing that.
         if (!domOption) {
            for (let i = 0; !domOption && i < options.length; i++) {
               if (options[i].get('data-value') === value) {
                  domOption = options[i];
                  break;
               }
            }
         }

         // If an option wasn't found, create a new option at the bottom.
         if (!domOption) {
            domOption = options.pop();
            domOption.removeClass('input-hidden-option');
            domOption.title = value;
            domOption.getChildren('.option-text').set('text', value);
            key && domOption.set('data-value', key);
         }
      }

      selectOption(dropdown, domOption);

      let newSelectedValue = domOption.get('data-value') || value;
      let newSelectedTitle = domOption.title || newSelectedValue;

      dropdown.set('data-value', newSelectedValue);
      dropdown.getElement('.selected').title = newSelectedTitle;
      if (fireValueChangedEvent !== false) {
         dropdown.fireEvent('valuechanged', dropdown);
      }

      dropdown.getElement('.selected strong').set('text', newSelectedTitle);

      let dropdownInput = dropdown.getElement('.js-dropdown-input');
      dropdownInput.value = newSelectedValue;
      // The "dropdown input" is a hidden field but we still need it to fire
      // off the "input" event for form manager logic to work. Hidden fields
      // don't get the "change" event naturally so we call it manually.
      dropdownInput.fireEvent('input', dropdownInput);

      return oldValue;
   }

   /**
    * Sets the highlighted value for a dropdown
    *
    * @param {DOM element} dropdown:
    *   .dropdown-wrapper element.
    * @param {DOM element} domOption (OPTIONAL - speed up):
    *   .selection element in dropdown's .options div
    */
   function selectOption(dropdown, domOption) {
      let currentlySelectedOption = dropdown.getElement('.currently-selected-option');

      if (currentlySelectedOption) {
         currentlySelectedOption.removeClass('currently-selected-option');
      }

      if (domOption) {
         domOption.addClass('currently-selected-option');
      }

      return domOption;
   }

   /*
   Execute the function 'setValue' to a .dropdown-wrapper.
   Ex: $$('.dropdown-wrapper').setValue('Hello World!');

   @param {Array | String | Number} value:
      new dropdown value to set.
      if value is an array then value is [key, value].
   @param {Boolean} fireValueChangedEvent (OPTIONAL - default = true):
      Boolean to fire the 'valuechanged' event or not.
   @return {String}
      dropdown's old value.
   */
   Element.implement('setValue', function (value, fireValueChangedEvent) {
      return setValue(this, value, null, fireValueChangedEvent);
   });

   /*
   Execute the function 'getValue' to a .dropdown-wrapper.
   Forces any intermediate-state dropdowns to finish setting their value,
   which is beneficial if a dropdown's value is both set & submitted in the
   same click event.
   Ex: $$('.dropdown-wrapper').getValue();

   @return {String}
      dropdown's value.
   */
   Element.implement('getValue', function () {
      if (this.hasClass('opened') || this.hasClass('input-opened')) {
         this.getElement('.input-options .more').fireEvent('click');
      }

      return this.get('data-value');
   });

   Element.implement('setDisabled', function (value) {
      if (value) {
         this.setAttribute('aria-disabled', true);
      } else {
         this.removeAttribute('aria-disabled');
      }
   });

   // Set up each dropdown element.
   function init(dropdown) {
      if (dropdown.isAttached === true) {
         return;
      }
      dropdown.isAttached = true;

      let input = dropdown.getElement('input'),
         maxLength = input.get('data-maxLength'),
         inputType = input.get('data-type'),
         moreButton = dropdown.getElement('.options .more'),
         closeInput = dropdown.getElements('.input-options .more'),
         selectedElem = dropdown.getElement('.selected'),
         allowsCustomInput = dropdown.getElement('.options .more') !== null,
         inputBuffer = '',
         enterCustomInputFromKeyboardSearch = false,
         options,
         traversableOptions,
         searchableOptions,
         initialSelectedOption,
         optionsOpenedEvents = {
            keypress: keyboardSearchStart,
            keydown: arrowkeyTraverse,
            keyup: close,
         };

      // Setting minWidth for elements with placeholder strings.
      if (!dropdown.get('data-value')) {
         dropdown.style.minWidth = dropdown.offsetWidth + 'px';
      }

      // Adding initial events that are never removed
      dropdown.addEvent('focus', setFocus);
      if (isIE) {
         // Workaround for an issue with 'click' firing twice on IE 11
         selectedElem.addEvent('mouseup', selected);
      } else {
         selectedElem.addEvent('click', selected);
      }
      dropdown.getElements('.options .selection').each(el => {
         el.addEvent('click', clickSection.bind(el));
      });
      closeInput.addEvent('click', closeCustomInput);

      // Prevent defaults and open dropdown on keypress
      function setFocus() {
         dropdown.addEvents({
            keydown: function (ev) {
               if (ev.key === 'space' || ev.key === 'down' || ev.key === 'up') {
                  ev.preventDefault();
               }
            },
            keyup: function (ev) {
               if (ev.key === 'space' || ev.key === 'down' || ev.key === 'up') {
                  ev.preventDefault();
                  selected();
               }
            },
         });
      }

      function getInitialSelectedOption() {
         return initialSelectedOption;
      }

      function setInitialSelectedOption() {
         initialSelectedOption = getCurrentlySelectedOption();
      }

      function getCurrentlySelectedOption() {
         return dropdown.getElement('.currently-selected-option');
      }

      // User selects a dropdown clickSection.
      function clickSection(ev) {
         selectOption(dropdown, this);
         closeOptions();
      }

      // User opens or closes the dropdown menu.
      function selected() {
         if (dropdown.get('aria-disabled') !== null) {
            // Close other open dropdowns on the page.
            closeByClickOut();
            return;
         }

         if (dropdown.hasClass('input-opened')) {
            return;
         } else if (dropdown.hasClass('opened')) {
            closeOptions();
         } else {
            openOptions();
         }
      }

      function openOptions() {
         // Close other open dropdowns on the page.
         closeByClickOut();

         setInitialSelectedOption();

         let currentlySelectedOption = getCurrentlySelectedOption();

         // Open the clicked dropdown.
         dropdown.addClass('opened');
         if (currentlySelectedOption) {
            currentlySelectedOption.focus();
         }
         // Set up variables and events for keyboardSearch.
         options = dropdown.getElements('.options .selection');
         traversableOptions = options.filter(el => !el.hasClass('input-hidden-option'));
         searchableOptions = options.filter(
            el => !(el.hasClass('input-hidden-option') || el.hasClass('more'))
         );
         dropdown.removeEvents('keydown');
         dropdown.removeEvents('keyup');
         dropdown.addEvents(optionsOpenedEvents);
      }

      function keyboardSearchStart(ev) {
         if (ev == null || (!ev.meta && ev.key !== 'enter')) {
            input.disabled = false;
            ev && ev.preventDefault();
            dropdown.addClass('keyboard-searching');
            input.focus();
            ev && (input.value = String.fromCharCode(ev.code));
            input.addEvent('input', keyboardSearch);
            dropdown.removeEvent('keypress', keyboardSearchStart);
            keyboardSearch();
         }
      }

      function keyboardSearch() {
         inputCheck();

         let searchResults = getSearchResults();

         if (searchResults.length) {
            // Set value to first option in the array.
            selectOption(dropdown, searchResults[0]).focus();
         } else {
            // Open the custom input view if search returns no results
            enterCustomInputFromKeyboardSearch = true;
            selectOption(dropdown, getInitialSelectedOption());
            openCustomInput();
         }

         input.focus();
      }

      function getSearchResults() {
         return searchableOptions.filter(
            val => !val.title.toLowerCase().indexOf(input.value.toLowerCase())
         );
      }

      function arrowkeyTraverse(ev) {
         if (ev.key === 'down' || ev.key === 'up') {
            ev.preventDefault();
            ev.key === 'down' ? traverseOptions(1) : traverseOptions(-1);
         }
      }

      function traverseOptions(direction) {
         let currentlySelectedOption = getCurrentlySelectedOption();

         let focusedIndex =
            (Math.max(traversableOptions.indexOf(currentlySelectedOption) + direction, -1) +
               traversableOptions.length) %
            traversableOptions.length;

         selectOption(dropdown, traversableOptions[focusedIndex]).focus();
      }

      function close(ev) {
         if (ev.key === 'enter' || ev.key === 'esc') {
            if (ev.key === 'esc') {
               selectOption(dropdown, getInitialSelectedOption());
               dropdown.removeClass('typing');
            }
            closeCustomInput(true);
         }
      }

      // User opens the custom input field.
      function openCustomInput() {
         closeOptions();
         dropdown.addClass('input-opened');
         input.disabled = false;
         if (input.value && allowsCustomInput) {
            dropdown.addClass('typing');
         }
         input
            .addEvents({
               input: enterCustomInput,
               keyup: close,
            })
            .focus();
      }

      // User closes the input field
      function closeCustomInput(focusOnClose) {
         // Input is valid, set it as dropdown's value.
         if (dropdown.hasClass('typing')) {
            dropdown.removeClass('typing');

            // Set the dropdown's value.
            setValue(dropdown, inputBuffer);
         }

         // Clear the buffer value and input value.
         inputBuffer = input.value = '';

         closeOptions(focusOnClose);
      }

      function enterCustomInput() {
         inputCheck();

         let inputValue = input.value;

         if (enterCustomInputFromKeyboardSearch && getSearchResults().length) {
            enterCustomInputFromKeyboardSearch = false;
            dropdown.removeClass('typing');
            closeOptions();
            openOptions();
            keyboardSearchStart();
         } else if (inputValue && allowsCustomInput) {
            dropdown.addClass('typing');
         } else {
            dropdown.removeClass('typing');
         }
      }

      // Validating custom input string to given rules.
      function inputCheck() {
         let inputValue = input.value;

         // IF the input adheres to the filters,
         // OR if the filter doesn't exist,
         // accept the input.
         if (
            (!maxLength || inputValue.length <= parseInt(maxLength, 10)) &&
            (inputType !== 'number' || /^\+?(0|[1-9]\d*)$/.test(inputValue) || inputValue === '')
         ) {
            inputBuffer = inputValue;
         } else {
            // ELSE, revert the input value back to the last accepted buffer.
            input.value = inputBuffer;
         }
      }

      // Closing the dropdown menu
      function closeOptions(focusOnClose) {
         let currentlySelectedOption = getCurrentlySelectedOption();

         // Removing all event listeners.
         dropdown.removeEvents(optionsOpenedEvents);
         input.removeEvents().blur();
         input.disabled = true;
         dropdown.addClass('closing');
         dropdown.removeClass('opened');
         dropdown.removeClass('input-opened');
         dropdown.removeClass('keyboard-searching');
         dropdown.blur();
         dropdown.addEventListener(transitionend, function onclose() {
            dropdown.removeClass('closing');
            dropdown.removeEventListener(transitionend, onclose);
            if (focusOnClose === true) {
               dropdown.focus();
            }
         });

         if (allowsCustomInput && currentlySelectedOption === moreButton) {
            selectOption(dropdown, getInitialSelectedOption());
            openCustomInput();
            return;
         }

         // If the user was keyboard searching through options,
         // fire valuechanged.
         if (currentlySelectedOption && currentlySelectedOption !== getInitialSelectedOption()) {
            setValue(dropdown, currentlySelectedOption.title, currentlySelectedOption);
         }
      }
   }

   // Clear out all the things
   function reset(dropdown) {
      if (!dropdown.get('data-value')) {
         return;
      }

      dropdown.set('data-value', '');

      let titleSpan = dropdown.getElement('span.selection, .selected');
      titleSpan
         .set('title', 'Select an Option')
         .set('html', '')
         .adopt(
            new Element('strong', {
               html: _js('Select an Option'),
            })
         );
      titleSpan.adopt(
         new Element('input', {
            class: 'dropdown-input',
            type: 'text',
            disabled: '',
            'data-maxlength': '',
         })
      );
      titleSpan.adopt(
         new Element('i', {
            class: 'fa fa-sort arrows',
         })
      );

      dropdown.getElement('input.js-dropdown-input').set('value', '');
      dropdown
         .getElement('.options')
         .getElement('.currently-selected-option')
         .removeClass('currently-selected-option');
   }

   /*
   Execute the function 'reset' on a .dropdown-wrapper.
   Returns the dropdown to its initial (unselected) state.
   Ex: $$('.dropdown-wrapper').clear();

   @return {Element}
      the dropdown.
   */
   Element.implement('clear', function () {
      reset(this);
      return this;
   });
}

onDomReady(doDropdownInit);
