import { Utils } from 'Shared/utils';
import { MediaItem } from './item';
import { setValidChoiceClass } from './item_display';

const MediaItemEmbed = new Class({
   Extends: MediaItem,

   /**
    * Returns a list of menu functions that should be allowed
    * for this media item in it's current state.
    *
    */
   _getMenuOptions: function () {
      return {};
   },

   /**
    * Creates an appropriate representation for the given MediaTarget and
    * passes it as the only argument to the provided callback when it's
    * ready.
    */
   createRepresentationFor: function (mediaTarget, callback) {
      let size = mediaTarget.options.displaySize;
      let self = this;
      self.whenReady(() => {
         let thumb = self.createDisplay(size, false);
         callback(thumb);
      });
   },

   /**
    * Instantiates a MediaItemEmbedDisplay for this embed
    * whose element will automatically update to reflect any state
    * changes in this item.
    */
   createDisplay: function (size, showFilename) {
      let thumb;
      size = this.getLargestImageSize(size) || 'standard';

      thumb = new MediaItemEmbedDisplay(this, {
         size: size,
         showFilename: showFilename,
      });

      return thumb;
   },

   /**
    * Returns the underlying objectid that this mediaItem represents.
    */
   getID: function () {
      return this.data.objectid();
   },

   getSrc: function (size) {
      return this.data[size]();
   },

   getType: function () {
      return this.data.type();
   },

   getFilter: function () {
      return 'onlyObjects';
   },

   toWikiText: function () {
      return '[embed|' + this.data.view_url() + ']';
   },

   /**
    * Returns the media type and underlying embedid that this mediaItem
    * represents.
    */
   getGlobalID: function () {
      return 'embed:' + this.getID();
   },
});

MediaItem.registerType('GuideEmbedObject', MediaItemEmbed);

/**
 * Represents a DOM element that displays a MediaItemEmbed
 *
 * options: {
 * }
 *
 * Fires Events:
 *    select(mediaItem): when the DOM element is clicked
 */
function MediaItemEmbedDisplay(mediaItem, options) {
   options = options || {};
   let data = mediaItem.data;
   let self = this;
   self.size = options.size;
   this.mediaItem = mediaItem;
   this._elementPromise = new Future();
   this._small = !MediaItem.isSizeAsBigAs(options.size, 'medium');

   // The container that holds everything.
   let container = (this.container = new Element('div.mediaItem.mediaObject'));

   // Add a menu control
   container.adopt(MediaItem.createMenuControl(mediaItem));

   if (this._small) {
      // Listen for click and fire "select" (the library listens for this)
      container.addEvent('click', this.fireEvent.pass(['select', mediaItem], this));
   }

   // Store a reference to this instance with the DOM element, so that other
   // code can always get back to the class from the DOM.
   container.store('mediaItem', mediaItem);

   // Create container for file name and type
   if (options.showFilename) {
      let filecontainer = new Element('span.file-container');
      let filetypeContainer = new Element('div.filetypeContainer');
      this.filename = new Element('p.filename');
      this.filetype = new Element('div.filetype');
      let icon = new Element('i.fileicon.fa.fa-youtube-play');

      this._updateFilename();

      filecontainer.grab(this.filename);
      container.grab(filetypeContainer);
      container.grab(filecontainer);
      container.grab(this.filetype);
      container.grab(icon);
   }

   mediaItem.addEvents({
      dataChanged: this._updateDisplay.bind(this),
      loadingChanged: this._updateLoading.bind(this),
      deleted: this.dispose.bind(this),
   });

   if (mediaItem.isReady()) {
      self._updateDisplay();
   } else {
      self._updateLoading(true);
   }
}

Object.append(MediaItemEmbedDisplay.prototype, {
   /**
    * Returns the DOM element that this instance represents.
    * This is the element that should be injected into the page.
    */
   getElement: function () {
      return this.container;
   },

   /**
    * Calls the readyCallback once the image is in the browser cache.
    *
    * Once the callback is called, it's safe to place this element in the DOM
    * without fear of long load-times.
    */
   whenReady: function (readyCallback) {
      if (this._elementPromise) {
         return this._elementPromise.getValue(readyCallback);
      } else {
         if (readyCallback) {
            readyCallback();
         }
         return true;
      }
   },

   /**
    * Updates the DOM element to reflect the current state of the
    * media item.
    */
   _updateDisplay: function () {
      setValidChoiceClass(this.mediaItem.isValidChoice(), this.container);

      this.size = this.mediaItem.getLargestImageSize(this.size);
      if (this._small) {
         this._updateImageDisplay();
         return;
      }

      this.container.appendHTML(this.mediaItem.data.html());
   },

   _updateImageDisplay: function () {
      let mediaItem = this.mediaItem;
      let self = this;
      let imgUrl = mediaItem.getSrc(self.size);

      // Create a new promise if this isn't the first time
      if (!self._elementPromise || self._displayElement) {
         self._elementPromise = new Future();
      }

      let img = self._displayElement;
      if (!img) {
         img = self._displayElement = new Element('img.' + self.size);
         self.container.grab(img);
      }
      img.set('src', imgUrl);

      // Give the UI thread a chance to catch up.
      (function () {
         self._elementPromise.resolve(imgUrl);
      }).delay(10);
   },

   _updateFilename: function () {
      if (!this.filename) {
         return;
      }

      let name = this.mediaItem.data.filename();

      this.filename.setProperties({
         text: name,
         title: name,
      });

      const provider = this.mediaItem.data.provider();
      this.filetype.setProperties({
         text: provider ? provider.toUpperCase() : '',
      });

      // Hide or show the filename element
      this.filename.toggle(name);
   },

   _updateLoading: function (isLoading) {
      let overlay = this._overlay();
      if (isLoading) {
         this.container.grab(overlay);
      } else {
         this.whenReady(() => {
            overlay.dispose();
         });
      }
   },

   dispose: function () {
      this.container.dispose();
      this.removeEvents();
   },

   _overlay: function () {
      this._overlayEl = this._overlayEl || new Element('div.loadingOverlay');
      return this._overlayEl;
   },

   inject: function (intoElement, option) {
      return this.container.inject(intoElement, option || 'after');
   },

   hide: function () {
      return this.container.hide();
   },

   show: function () {
      return this.container.show();
   },
});

Object.append(MediaItemEmbedDisplay.prototype, Utils.EventsFunctions);
