/***
 * MooCrop (v. rc-1 - 2007-10-24 )
 *
 * @version			rc-1
 * @license			BSD-style license
 * @author			nwhite - < nw [at] nwhite.net >
 * @infos			http://www.nwhite.net/MooCrop/
 * @copyright		Author
 *
 * @modifiedby    Dave, July 2008
 */
// eslint-disable-next-line no-var
export var MooCrop = (window.MooCrop = new Class({
   Implements: [Options, Events],

   calculateHandles: true,
   current: {},

   options: {
      maskColor: 'black',
      maskOpacity: '.4',
      handleColor: '#999',
      handleBorder: '1px solid #333',
      handleWidth: '12px',
      handleHeight: '12px',
      cropBorder: '1px dashed #aaa',
      min: { width: 60, height: 45 },
      start: { top: 0, left: 0, width: 592, height: 444 },
      showMask: true,
      showHandles: false, // hide handles on drag
      ratio: 'FOUR_THREE',
      container: {
         width: 800,
         height: 600,
      },
   },

   initialize: function (el, options) {
      if (options.ratio == 'ONE_ONE') {
         options.start.width = options.start.height;
      } else if (options.adjustRatio) {
         // if the image is out of aspect, set the default crop to the top-left
         // corner with the minimum dimensions to ensure it will fit
         options.start.top = options.start.left = 0;
         options.start.height = options.min.height;
         options.start.width = options.min.width;
      }

      switch (options.ratio) {
         case 'FOUR_THREE': {
            options.ratio = 0.75;
            break;
         }
         case 'ONE_ONE': {
            options.ratio = 1;
            break;
         }
         default: {
            options.ratio = false;
         }
      }

      this.setOptions(options);
      options = this.options;
      let img = (this.img = $(el));
      // Ensure min size is smaller than image.
      options.min.width = Math.min(img.width, options.min.width);
      options.min.height = Math.min(img.height, options.min.height);

      this.resizeFunc = this.refresh.bind(this);
      this.removeFunc = this.removeListener.bind(this);

      this.buildOverlay();
      this.setup();
   },

   setup: function () {
      $(this.cropArea).setStyles({
         width: this.options.start.width,
         height: this.options.start.height,
         top: this.options.start.top,
         left: this.options.start.left,
      });

      this.current.crop = this.crop = this.getCropArea();

      this.handleWidthOffset = this.options.handleWidth.toInt() / 2;
      this.handleHeightOffset = this.options.handleHeight.toInt() / 2;

      this.fixBoxModel();
      this.drawMasks();
      this.positionHandles();
   },

   getCropArea: function () {
      let crop = this.cropArea.getCoordinates();
      crop.left -= this.offsets.x;
      crop.right -= this.offsets.x; // calculate relative (horizontal)
      crop.top -= this.offsets.y;
      crop.bottom -= this.offsets.y; // calculate relative (vertical)

      return crop;
   },

   fixBoxModel: function () {
      let diff = (this.boxDiff = (this.crop.width - this.options.start.width) / 2);

      let b = (this.bounds = {
         top: diff,
         left: diff,
         right: this.img.width + diff * 2,
         bottom: this.img.height + diff * 2,
         width: this.options.min.width + diff * 2,
         height: this.options.min.height + diff * 2,
      });

      this.wrapper.setStyles({
         width: b.right,
         height: b.bottom,
      });
      // Offset the image by the width of the crop border
      this.img.setStyles({
         top: diff,
         left: diff,
      });
      this.north.setStyle('width', b.right);
      this.south.setStyle('width', b.right);
   },

   activate: function (handle, event) {
      event.stop();
      this.current = { x: event.page.x, y: event.page.y, handle: handle, crop: this.current.crop };
      if (this.current.handle == 'NESW' && !this.options.showHandles) {
         this.hideHandles();
      }
      this.fireEvent('onBegin', [this.img.src, this.getCropInfo(), this.bounds, handle]);
      document.addListener('mousemove', this.resizeFunc);
      document.addListener('mouseup', this.removeFunc);
      document.addListener('touchmove', this.resizeFunc);
      document.addListener('touchend', this.removeFunc);
   },

   removeListener: function () {
      if (this.current.handle == 'NESW' && !this.options.showHandles) {
         this.showHandles();
      }
      document.removeListener('mousemove', this.resizeFunc);
      document.removeListener('mouseup', this.removeFunc);
      document.removeListener('touchmove', this.resizeFunc);
      document.removeListener('touchend', this.removeFunc);
      this.crop = this.current.crop;
      this.fireEvent('onComplete', [
         this.img.src,
         this.getCropInfo(),
         this.bounds,
         this.current.handle,
      ]);
   },

   /**
    * Do the thing. -Dave
    */
   refresh: function (event) {
      let sign = 0;
      event = window.TouchEvent && event instanceof TouchEvent ? event.targetTouches[0] : event;
      let xdiff = this.current.x - event.pageX;
      let ydiff = this.current.y - event.pageY;

      let b = this.bounds;
      let c = this.crop;
      let handle = this.current.handle;
      let styles = {}; //saving bytes
      let dragging = handle.length > 2 ? true : false;

      // 1. enforce the borders
      if (
         handle.contains('S') && //SOUTH
         c.bottom - ydiff > b.bottom
      ) {
         ydiff = c.bottom - b.bottom;
      } // box south
      if (
         handle.contains('N') && //NORTH
         c.top - ydiff < b.top
      ) {
         ydiff = c.top;
      } //box north
      if (
         handle.contains('E') && //EAST
         c.right - xdiff > b.right
      ) {
         xdiff = c.right - b.right;
      } //box east
      if (
         handle.contains('W') && //WEST
         c.left - xdiff < b.left
      ) {
         xdiff = c.left;
      } //box west

      // 2. adjust width/height to match the aspect ratio
      if (this.options.ratio && !dragging) {
         // X-axis
         if (Math.abs(xdiff) > Math.abs(ydiff) * this.options.ratio) {
            ydiff =
               handle == 'SE' || handle == 'NW'
                  ? Math.round(xdiff * this.options.ratio)
                  : -Math.round(xdiff * this.options.ratio);

            // 3. enforce the borders again
            let oldydiff = ydiff;
            if (
               handle.contains('S') && //SOUTH
               c.bottom - ydiff > b.bottom
            ) {
               ydiff = c.bottom - b.bottom; // box south
               sign = handle == 'SE' || handle == 'NW' ? -1 : 1;
            }
            if (
               handle.contains('N') && //NORTH
               c.top - ydiff < b.top
            ) {
               ydiff = c.top; //box north
               sign = handle == 'SE' || handle == 'NW' ? -1 : 1;
            }

            if (sign) {
               xdiff += sign * Math.round((oldydiff - ydiff) / this.options.ratio);
            }
         } else {
            // Y-axis
            xdiff =
               handle == 'SE' || handle == 'NW'
                  ? Math.round(ydiff / this.options.ratio)
                  : -Math.round(ydiff / this.options.ratio);

            // 3. enforce the borders again
            sign = 0;
            let oldxdiff = xdiff;
            if (
               handle.contains('E') && //EAST
               c.right - xdiff > b.right
            ) {
               xdiff = c.right - b.right; //box east
               sign = handle == 'SE' || handle == 'NW' ? -1 : 1;
            }
            if (
               handle.contains('W') && //WEST
               c.left - xdiff < b.left
            ) {
               xdiff = c.left; //box west
               sign = handle == 'SE' || handle == 'NW' ? -1 : 1;
            }

            if (sign) {
               ydiff += sign * Math.round((oldxdiff - xdiff) * this.options.ratio);
            }
         }
      }

      // 4. apply the changes
      if (
         handle.contains('S') && //SOUTH
         !dragging
      ) {
         if (c.height - ydiff < b.height) {
            ydiff = c.height - b.height;
         } // size south
         styles['height'] = c.height - ydiff; // South handles only
      }
      if (handle.contains('N')) {
         //NORTH
         if (!dragging) {
            if (c.height + ydiff < b.height) {
               ydiff = b.height - c.height;
            } // size north
            styles['height'] = c.height + ydiff; // North handles only
         }
         styles['top'] = c.top - ydiff; // both Drag and N handles
      }
      if (
         handle.contains('E') && //EAST
         !dragging
      ) {
         if (c.width - xdiff < b.width) {
            xdiff = c.width - b.width;
         } // size east
         styles['width'] = c.width - xdiff;
      }
      if (handle.contains('W')) {
         //WEST
         if (!dragging) {
            if (c.width + xdiff < b.width) {
               xdiff = b.width - c.width;
            } //size west
            styles['width'] = c.width + xdiff;
         }
         styles['left'] = c.left - xdiff; // both Drag and W handles
      }

      let preCssStyles = Object.clone(styles);
      if (styles.width != null) {
         styles.width -= this.boxDiff * 2;
      }
      if (styles.height != null) {
         styles.height -= this.boxDiff * 2;
      }

      this.cropArea.setStyles(styles);
      this.getCurrentCoords(preCssStyles);
      this.drawMasks();
      this.positionHandles();
      this.fireEvent('onCrop', [this.img.src, this.getCropInfo(), b, handle]);
   },

   getCurrentCoords: function (changed) {
      let current = Object.clone(this.crop);

      if (changed.left != null) {
         current.left = changed.left;
         if (changed.width == null) {
            current.right = current.left + current.width;
         } else {
            current.width = changed.width;
         }
      }
      if (changed.top != null) {
         current.top = changed.top;
         if (changed.height == null) {
            current.bottom = current.top + current.height;
         } else {
            current.height = changed.height;
         }
      }
      if (changed.width != null && changed.left == null) {
         current.width = changed.width;
         current.right = current.left + current.width;
      }
      if (changed.height != null && changed.top == null) {
         current.height = changed.height;
         current.bottom = current.top + current.height;
      }
      this.current.crop = current;
   },

   drawMasks: function () {
      if (!this.options.showMask) {
         return;
      }
      let b = this.bounds;
      let c = this.current.crop;
      let handle = this.current.handle;
      this.north.setStyle('height', c.top);
      this.south.setStyle('height', b.bottom - c.bottom);
      this.east.setStyles({
         height: c.height,
         width: b.right - c.right,
         top: c.top,
         left: c.right,
      });
      this.west.setStyles({ height: c.height, width: c.left, top: c.top });
   },

   positionHandles: function () {
      if (!this.calculateHandles) {
         return;
      }
      let c = this.current.crop;
      let wOffset = this.handleWidthOffset + this.bounds.left;
      let hOffset = this.handleHeightOffset + this.bounds.top;

      if (!this.options.ratio) {
         this.handles.get('N').setStyles({ left: c.width / 2 - wOffset, top: -hOffset });
         this.handles.get('E').setStyles({ left: c.width - wOffset, top: c.height / 2 - hOffset });
         this.handles.get('S').setStyles({ left: c.width / 2 - wOffset, top: c.height - hOffset });
         this.handles.get('W').setStyles({ left: -wOffset, top: c.height / 2 - hOffset });
      }
      this.handles.get('NE').setStyles({ left: c.width - wOffset, top: -hOffset });
      this.handles.get('SE').setStyles({ left: c.width - wOffset, top: c.height - hOffset });
      this.handles.get('SW').setStyles({ left: -wOffset, top: c.height - hOffset });
      this.handles.get('NW').setStyles({ left: -wOffset, top: -hOffset });
   },

   hideHandles: function () {
      this.calculateHandles = false;
      this.handles.each(handle => {
         handle.setStyle('display', 'none');
      });
   },

   showHandles: function () {
      this.calculateHandles = true;
      this.positionHandles();
      this.handles.each(handle => {
         handle.setStyle('display', 'block');
      });
   },

   buildOverlay: function () {
      let o = this.options;

      this.wrapper = new Element('div', {
         styles: {
            position: 'relative',
            width: this.img.width,
            height: this.img.height,
            float: this.img.getStyle('float'),

            // center the div in the container
            'margin-left': Math.round((o.container.width - this.img.width) / 2),
         },
      }).inject(this.img, 'before');
      this.wrapper.grab(this.img);
      this.img.setStyle('position', 'absolute');

      this.offsets = { x: this.wrapper.getLeft(), y: this.wrapper.getTop() };

      if (this.options.showMask) {
         // optional masks
         let maskStyles = {
            left: 0,
            position: 'absolute',
            overflow: 'hidden',
            'background-color': o.maskColor,
            opacity: o.maskOpacity,
         };
         this.north = new Element('div', { styles: maskStyles }).inject(this.wrapper, 'inside');
         this.south = new Element('div', {
            styles: Object.merge(maskStyles, { bottom: 0 }),
         }).inject(this.wrapper, 'inside');
         this.east = new Element('div', { styles: maskStyles }).inject(this.wrapper, 'inside');
         this.west = new Element('div', { styles: maskStyles }).inject(this.wrapper, 'inside');
      }

      this.cropArea = new Element('div', {
         styles: { position: 'absolute', top: 0, left: 0, border: o.cropBorder, cursor: 'move' },
         events: {
            dblclick: function () {
               this.fireEvent('onDblClk', [this.img.src, this.getCropInfo(), this.bounds]);
            }.bind(this),
            mousedown: this.activate.bind(this, 'NESW'),
            touchstart: this.activate.bind(this, 'NESW'),
         },
      }).inject(this.wrapper, 'inside');

      this.handles = new Hash();
      let showHandles = this.options.ratio
         ? ['NE', 'SE', 'SW', 'NW']
         : ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

      showHandles.forEach(function (handle) {
         this.handles.set(
            handle,
            new Element('div', {
               styles: {
                  position: 'absolute',
                  'margin-left': -1,
                  'margin-top': -1,
                  'background-color': o.handleColor,
                  border: o.handleBorder,
                  width: o.handleWidth,
                  height: o.handleHeight,
                  overflow: 'hidden',
                  cursor: handle.toLowerCase() + '-resize',
               },
               events: {
                  mousedown: this.activate.bind(this, handle),
                  touchstart: this.activate.bind(this, handle),
               },
            })
               .addClass('cropHandle')
               .inject(this.cropArea, 'inside')
         );
      }, this);
   },

   getCropInfo: function () {
      let c = Object.clone(this.current.crop);
      c.width -= this.boxDiff * 2;
      c.height -= this.boxDiff * 2;
      return c;
   },

   removeOverlay: function () {
      this.wrapper.destroy();
      this.img.setStyle('display', 'block');
   },

   setImage: function (src) {
      this.img.src = src;
      this.wrapper.setStyle(
         'background',
         'url(' + this.img.src + ') no-repeat ' + this.boxDiff + 'px ' + this.boxDiff + 'px'
      );
   },
}));
