/**
 * Add or remove a class on `container` which indicates whether
 * this item is a valid choice in the current context, based on the
 * value of `isValid`.
 */
export function setValidChoiceClass(isValid, container) {
   if (isValid) {
      container.removeClass('invalid-choice');
   } else {
      container.addClass('invalid-choice');
   }
}
